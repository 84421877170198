import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Load the Google Analytics script asynchronously
(function () {
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-3EK0Q9FPJQ";
  script.async = true;
  document.head.appendChild(script);

  // Initialize Google Analytics
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-3EK0Q9FPJQ");
  };
})();
