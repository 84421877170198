import "./App.css";
import { MicMode } from "./components/MicMode";
import { PhoneMode } from "./components/PhoneMode";
import { useState } from "react";
import {
  AcademicCapIcon,
  CurrencyDollarIcon,
  ForwardIcon,
  LanguageIcon,
  ShieldCheckIcon,
  WrenchIcon,
} from "@heroicons/react/20/solid";

function App() {
  const [micMode, setMicMode] = useState(true);

  return (
    <div className="flex flex-col items-center h-screen w-full bg-gradient-to-bl from-amber-500/50 to-50% dark:from-blue-950 dark:via-transparent">
      <div className="flex flex-col h-full sm:w-full md:w-11/12 lg:4/5 items-center px-4">
        <div className="flex flex-row m-4 h-18 w-full justify-between items-center">
          <img className="h-16" src="/logo.svg" alt="TranslateMyCall" />
          <div className="hidden md:block relative">
            <a
              href="https://calendly.com/kolchinski/30min"
              target="_blank"
              className="md:w-32 py-3 px-4 justify-center items-center gap-x-2 text-md font-medium rounded-lg border border-transparent bg-blue-700 text-white hover:bg-blue-800 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none shadow-md"
              onClick={() => {
                window.gtag("event", "click", {
                  event_category: "button",
                  event_label: "first_contact_button",
                });
              }}
            >
              Learn more
            </a>
          </div>
        </div>

        <div className="flex flex-grow justify-center w-full items-center">
          <div className="max-w-[85rem] px-4 mx-auto">
            <div className="grid md:grid-cols-2 gap-6 lg:gap-8">
              <div className="md:mb-12 max-w-2xl">
                <h1 className="mb-4 font-semibold text-gray-800 text-4xl lg:text-5xl dark:text-neutral-200">
                  AI Interpreting for your LSP
                </h1>
                <p className="text-gray-600 dark:text-neutral-400">
                  AI interpreting is here, and it’s about to change the language
                  industry just like NMT has. We'll help you offer it to your
                  customers so you can stay ahead of the curve.
                </p>

                <blockquote className="hidden md:block relative">
                  <footer className="mt-9">
                    <div className="space-y-4 lg:space-y-6">
                      <div className="flex items-center gap-x-4 sm:gap-x-6">
                        <span className="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                          <CurrencyDollarIcon className="size-6" />
                        </span>
                        <div className="grow">
                          <h3 className="text-base font-semibold text-gray-800 dark:text-neutral-200">
                            $.10 to $.30 per minute{" "}
                            <span className="inline text-base font-normal text-gray-600 dark:text-neutral-400">
                              depending on use case.
                            </span>
                          </h3>
                        </div>
                      </div>

                      <div className="flex items-center gap-x-4 sm:gap-x-6">
                        <span className="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                          <LanguageIcon className="size-6" />
                        </span>
                        <div className="grow">
                          <h3 className="text-base font-semibold text-gray-800 dark:text-neutral-200">
                            32 languages supported
                            <span className="inline text-base font-normal text-gray-600 dark:text-neutral-400">
                              ; more available upon request.
                            </span>
                          </h3>
                        </div>
                      </div>

                      <div className="flex items-center gap-x-4 sm:gap-x-6">
                        <span className="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                          <ShieldCheckIcon className="size-6" />
                        </span>
                        <div className="grow">
                          <h3 className="text-base font-semibold text-gray-800 dark:text-neutral-200">
                            HIPAA compliant and offline{" "}
                            <span className="inline text-base font-normal text-gray-600 dark:text-neutral-400">
                              (on-prem) models available.
                            </span>
                          </h3>
                        </div>
                      </div>

                      <div className="flex items-center gap-x-4 sm:gap-x-6">
                        <span className="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                          <WrenchIcon className="size-6" />
                        </span>
                        <div className="grow">
                          <h3 className="text-base font-semibold text-gray-800 dark:text-neutral-200">
                            Per-job customization {" "}
                            <span className="inline text-base font-normal text-gray-600 dark:text-neutral-400">
                              available: glossaries, style guides etc.
                            </span>
                          </h3>
                        </div>
                      </div>

                      <div className="flex items-center gap-x-4 sm:gap-x-6">
                        <span className="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                          <AcademicCapIcon className="size-6" />
                        </span>
                        <div className="grow">
                          <h3 className="text-base font-semibold text-gray-800 dark:text-neutral-200">
                            Team of Stanford AI Lab & YC Alumni{" "}
                            <span className="inline text-base font-normal text-gray-600 dark:text-neutral-400">
                              led by{" "}
                              <a
                                href="https://www.linkedin.com/in/kolchinski/"
                                className="text-blue-700 hover:underline"
                              >
                                Alex Kolchinski
                              </a>
                              .
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>

              <div className="lg:max-w-lg lg:mx-auto lg:me-0 ms-auto mb-6 w-full">
                <div className="p-4 sm:p-7 flex flex-col bg-white rounded-2xl shadow-lg">
                  <h2 className="text-3xl font-bold text-gray-800 mb-4">
                    Try it now!
                  </h2>

                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex" aria-label="Tabs">
                      <button
                        className={`whitespace-nowrap px-4 py-2 text-sm font-medium rounded-t-lg ${
                          micMode
                            ? "bg-blue-600 text-white"
                            : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                        }`}
                        onClick={() => setMicMode(true)}
                      >
                        Try it here
                      </button>
                      <button
                        className={`whitespace-nowrap px-4 py-2 text-sm font-medium rounded-t-lg ${
                          !micMode
                            ? "bg-blue-600 text-white"
                            : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                        }`}
                        onClick={() => setMicMode(false)}
                      >
                        Phone call (OPI)
                      </button>
                    </nav>
                  </div>

                  {micMode ? <MicMode /> : <PhoneMode />}
                </div>

                {/* Divider with "or" */}
                <div className="flex items-center lg:max-w-lg lg:mx-auto lg:me-0 ms-auto w-full mt-9 mb-2">
                  <div className="flex-grow border-t border-gray-300"></div>
                  <span className="mx-4 text-gray-500 font-medium">or</span>
                  <div className="flex-grow border-t border-gray-300"></div>
                </div>

                {/* New container duplicating the outer container's classes */}
                <div className="lg:max-w-lg lg:mx-auto lg:me-0 ms-auto mb-0 w-full">
                  {/* Inner container to apply padding */}
                  <div className="p-4 sm:p-7 flex flex-col">
                    {/* "Contact us" button */}
                    <a
                      href="https://calendly.com/kolchinski/30min"
                      className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-lg font-medium rounded-lg bg-amber-500 text-white hover:bg-amber-600 focus:outline-none shadow-md"
                      target="_blank"
                      onClick={() => {
                        window.gtag("event", "click", {
                          event_category: "button",
                          event_label: "second_contact_button",
                        });
                      }}
                    >
                      Learn more
                    </a>
                    <p className="mt-9 text-center text-sm text-gray-600">
                      or email alex@fluxinterpreting.com.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
