import { useEffect, useState, useRef } from "react";
import LanguageSelect from "./LanguageSelect";
import { CODE2LANG } from "../constants";
import { MicrophoneIcon, StopCircleIcon } from "@heroicons/react/20/solid";

export function MicMode() {
  const [recording, setRecording] = useState(false);

  const [yourCountry, setYourCountry] = useState("");
  const [theirCountry, setTheirCountry] = useState("");

  const recorderRef = useRef(null);
  const websocketRef = useRef(null);

  const audioContextRef = useRef(null);
  const audioQueueRef = useRef([]);
  const isPlayingRef = useRef(false);

  const onMessage = async (event) => {
    const data = event.data;
    const base64Audio = JSON.parse(data).audio;
    audioQueueRef.current.push(base64Audio);
    playAudioQueue();
  };

  const playAudioQueue = async () => {
    if (isPlayingRef.current || audioQueueRef.current.length === 0) {
      return;
    }

    isPlayingRef.current = true;

    const base64Audio = audioQueueRef.current[0];
    try {
      const binaryString = atob(base64Audio);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const arrayBuffer = bytes.buffer;
      const audioBuffer = await audioContextRef.current.decodeAudioData(
        arrayBuffer
      );

      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);

      source.start();
      source.onended = () => {
        isPlayingRef.current = false;
        audioQueueRef.current = audioQueueRef.current.slice(1);
        playAudioQueue();
      };
    } catch (error) {
      console.error("Error decoding or playing audio:", error);
      isPlayingRef.current = false;
      audioQueueRef.current = audioQueueRef.current.slice(1);
      playAudioQueue();
    }
  };

  const processAudioEvent = (event) => {
    const inputBuffer = event.inputBuffer.getChannelData(0);
    let audioDataToSend;

    // Check if the sample rate is different from 24000 Hz
    if (audioContextRef.current.sampleRate !== 24000) {
      // Resample the audio to 24000 Hz
      const resampledBuffer = downsampleBuffer(
        inputBuffer,
        audioContextRef.current.sampleRate,
        24000
      );
      audioDataToSend = resampledBuffer;
    } else {
      // If already 24000 Hz, use the original buffer
      audioDataToSend = inputBuffer;
    }

    const int16Array = new Int16Array(audioDataToSend.length);
    for (let i = 0; i < audioDataToSend.length; i++) {
      int16Array[i] =
        Math.max(-1, Math.min(1, audioDataToSend[i])) < 0
          ? Math.floor(audioDataToSend[i] * 32768)
          : Math.ceil(audioDataToSend[i] * 32767);
    }

    const byteArray = new Uint8Array(int16Array.buffer);
    const base64Audio = btoa(String.fromCharCode.apply(null, byteArray));
    const msg = {
      audio: base64Audio,
    };

    if (
      websocketRef.current &&
      websocketRef.current.readyState === websocketRef.current.OPEN
    ) {
      websocketRef.current.send(JSON.stringify(msg));
    }
  };

  const audioStreamHandler = (stream) => {
    recorderRef.current = new MediaRecorder(stream);

    recorderRef.current.onstart = () => {
      if (!audioContextRef.current) {
        try {
          audioContextRef.current = new (window.AudioContext ||
            window.webkitAudioContext)({
            sampleRate: 24000,
          });
        } catch (error) {
          audioContextRef.current = new (window.AudioContext ||
            window.webkitAudioContext)();
        }
      }

      console.log("Recording started");

      const lang1 = CODE2LANG.find((obj) => obj.code === yourCountry).lang;
      const lang2 = CODE2LANG.find((obj) => obj.code === theirCountry).lang;

      websocketRef.current = new WebSocket(
        `wss://fluxinterpreting.com/api/browser-audio-stream/${lang1}/${lang2}`
      );
      websocketRef.current.addEventListener("message", onMessage);

      const source = audioContextRef.current.createMediaStreamSource(stream);
      const processor = audioContextRef.current.createScriptProcessor(
        1024,
        1,
        1
      );

      source.connect(processor);
      processor.connect(audioContextRef.current.destination);
      processor.onaudioprocess = processAudioEvent;
    };

    recorderRef.current.onstop = () => {
      console.log("Recording stopped");
      websocketRef.current.close();
    };
  };

  function downsampleBuffer(buffer, originalSampleRate, targetSampleRate) {
    if (targetSampleRate === originalSampleRate) {
      return buffer;
    }

    const sampleRateRatio = originalSampleRate / targetSampleRate;
    const newLength = Math.round(buffer.length / sampleRateRatio);
    const resampledBuffer = new Float32Array(newLength);

    for (let i = 0; i < newLength; i++) {
      const index = i * sampleRateRatio;
      const floorIndex = Math.floor(index);
      const ceilIndex = Math.min(floorIndex + 1, buffer.length - 1);
      const weight = index - floorIndex;
      resampledBuffer[i] =
        buffer[floorIndex] * (1 - weight) + buffer[ceilIndex] * weight;
    }

    return resampledBuffer;
  }

  const startRecording = () => {
    setRecording(true);
    // if (!audioContextRef.current) {
    // try {
    //   audioContextRef.current = new (window.AudioContext ||
    //     window.webkitAudioContext)({
    //     sampleRate: 24000,
    //   });
    // } catch (error) {
    //   audioContextRef.current = new (window.AudioContext ||
    //     window.webkitAudioContext)();
    // }
    // }
    recorderRef.current.start();
  };

  const stopRecording = () => {
    setRecording(false);
    recorderRef.current.stop();
    // audioContextRef.current.close();
  };

  useEffect(() => {
    if (yourCountry && theirCountry) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(audioStreamHandler);
    }

    // if (!audioContextRef.current) {
    //   audioContextRef.current = new (window.AudioContext ||
    //     window.webkitAudioContext)();
    // }

    if (audioQueueRef.current.length > 0 && !isPlayingRef.current) {
      playAudioQueue();
    }
  }, [audioQueueRef.current, yourCountry, theirCountry]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full mt-6">
        <p className="text-gray-800">
          Select <b>your</b> language
        </p>
        <LanguageSelect
          selected={yourCountry}
          setSelected={setYourCountry}
          options={CODE2LANG}
          disabled={recording}
        />
      </div>

      <div className="flex flex-col w-full mt-6 mb-8">
        <p className="text-gray-800">
          Select <b>their</b> language
        </p>
        <LanguageSelect
          selected={theirCountry}
          setSelected={setTheirCountry}
          options={CODE2LANG}
          disabled={recording}
        />
      </div>

      {!recording && (
        <button
          className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-700 text-white hover:bg-blue-800 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none shadow-sm"
          onClick={() => {
            startRecording();
            // window.gtag("event", "click", {
            //   event_category: "button",
            //   event_label: "interpret_with_flux_ai_button",
            // });
          }}
          disabled={!yourCountry || !theirCountry}
        >
          <MicrophoneIcon className="h-6 w-6" />
          Interpret with Flux AI
        </button>
      )}
      {recording && (
        <button
          className="animate-pulse w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-red-700 text-white hover:bg-red-800 focus:outline-none focus:bg-red-700 disabled:opacity-50 disabled:pointer-events-none shadow-sm"
          onClick={stopRecording}
        >
          <StopCircleIcon className="h-6 w-6" />
          Finish interpreting
        </button>
      )}
    </div>
  );
}
