"use client";

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

export default function LanguageSelect({
  selected,
  setSelected,
  options,
  disabled,
}) {
  let selectedLang = options.find((c2l) => c2l.code === selected);
  if (!selectedLang) {
    selectedLang = options[options.length - 1];
  }

  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      <div className="relative mt-2" style={{ width: "100%" }}>
        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
          <span className="flex items-center">
            <div
              className={`h-5 w-5 mb-1 ${disabled ? "filter grayscale" : ""}`}
            >
              {selectedLang.code === "ZZ"
                ? "🌎"
                : getUnicodeFlagIcon(selectedLang.code)}
            </div>
            <span className="ml-3 block truncate">
              <div className={disabled ? "text-gray-300" : ""}>
                {selectedLang.lang}
              </div>
            </span>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <ChevronUpDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {options.map((c2l, idx) =>
            c2l.code === "ZZ" ? (
              <div key={idx}></div>
            ) : (
              <ListboxOption
                key={idx}
                value={c2l.code}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-gray-100 data-[focus]:text-black"
              >
                <div className="flex items-center">
                  <div className="h-5 w-5">{getUnicodeFlagIcon(c2l.code)}</div>
                  <span className="ml-3 block truncate text-base group-data-[selected]:font-semibold">
                    {c2l.lang}
                  </span>
                </div>

                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                  <CheckIcon aria-hidden="true" className="h-5 w-5" />
                </span>
              </ListboxOption>
            )
          )}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
