export const COUNTRY_CODES = [
  "au",
  "nz",
  "in",
  "hk",
  "bd",
  "id",
  "jp",
  "kr",
  "my",
  "mn",
  "sg",
  "th",
  "vn",
  "cr",
  "do",
  "mx",
  "pr",
  "us",
  "ca",
  "br",
  "co",
  "cz",
  "dk",
  "fr",
  "de",
  "hu",
  "is",
  "no",
  "pl",
  "ro",
  "sk",
  "se",
  "sa",
  "tw",
  "tr",
  "gb",
];

export const CODE2LANG = [
  { code: "US", lang: "English", speakers: 1500 },
  { code: "CN", lang: "Mandarin", speakers: 1100 },
  { code: "MX", lang: "Spanish", speakers: 580 },
  { code: "SA", lang: "Arabic", speakers: 422 },
  { code: "IN", lang: "Hindi", speakers: 600 },
  { code: "FR", lang: "French", speakers: 280 },
  { code: "BR", lang: "Portuguese", speakers: 265 },
  { code: "RU", lang: "Russian", speakers: 258 },
  { code: "ID", lang: "Indonesian", speakers: 199 },
  { code: "DE", lang: "German", speakers: 135 },
  { code: "JP", lang: "Japanese", speakers: 128 },
  { code: "VN", lang: "Vietnamese", speakers: 95 },
  { code: "KR", lang: "Korean", speakers: 77 },
  { code: "TR", lang: "Turkish", speakers: 75 },
  { code: "TH", lang: "Thai", speakers: 70 },
  { code: "IT", lang: "Italian", speakers: 67 },
  { code: "PL", lang: "Polish", speakers: 50 },
  { code: "UA", lang: "Ukrainian", speakers: 40 },
  { code: "MY", lang: "Malay", speakers: 33 },
  { code: "RO", lang: "Romanian", speakers: 28 },
  { code: "NL", lang: "Dutch", speakers: 24 },
  { code: "GR", lang: "Greek", speakers: 13 },
  { code: "HU", lang: "Hungarian", speakers: 13 },
  { code: "CZ", lang: "Czech", speakers: 10 },
  { code: "SE", lang: "Swedish", speakers: 10 },
  { code: "FI", lang: "Finnish", speakers: 6 },
  { code: "DK", lang: "Danish", speakers: 6 },
  { code: "NO", lang: "Norwegian", speakers: 5 },
  { code: "BG", lang: "Bulgarian", speakers: 7 },
  //{ code: "LV", lang: "Latvian", speakers: 2 },
  //{ code: "LT", lang: "Lithuanian", speakers: 3 },
  //{ code: "EE", lang: "Estonian", speakers: 1.1 },
  //{ code: "MT", lang: "Maltese", speakers: 0.5 },
  //{ code: "HR", lang: "Croatian", speakers: 5 },
  //{ code: "SI", lang: "Slovenian", speakers: 2.5 },
  //{ code: "IE", lang: "Irish", speakers: 1.8 },
  //{ code: "MN", lang: "Mongolian", speakers: 5.2 },
  { code: "IL", lang: "Hebrew", speakers: 9 },
  { code: "IR", lang: "Persian", speakers: 80 },
  { code: "HK", lang: "Cantonese", speakers: 85 },
  { code: "ZZ", lang: "Language", speakers: 0 },
];

CODE2LANG.sort((a, b) => b.speakers - a.speakers);
